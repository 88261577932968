// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../../api/Api.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Api_User from "../../../../../api/users/Api_User.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../../../routes/common/Routes_User.res.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../../../bindings/ReactTelInput.res.js";
import * as Globals from "Common/globals";
import * as IconUserAvatar from "../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as TelephoneField from "../../../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileData from "../../../../user-dashboard/edit-profile/UserEditProfileData.res.js";
import * as IconAdditionalUpload from "../../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as UserDashboardEditFormScss from "./UserDashboardEditForm.scss";

var css = UserDashboardEditFormScss;

var validators_firstName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.firstName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.firstName
              };
      } else {
        return {
                TAG: "Error",
                _0: "First name is required"
              };
      }
    })
};

var validators_lastName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.lastName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.lastName
              };
      } else {
        return {
                TAG: "Error",
                _0: "Last name is required"
              };
      }
    })
};

var validators = {
  firstName: validators_firstName,
  lastName: validators_lastName,
  dialCode: undefined,
  dialCodeCountry: undefined,
  phone: undefined,
  providerNameRequested: undefined,
  jobTitle: undefined,
  description: undefined,
  profilePic: undefined
};

function initialFieldsStatuses(_input) {
  return {
          firstName: "Pristine",
          lastName: "Pristine",
          dialCode: "Pristine",
          dialCodeCountry: "Pristine",
          phone: "Pristine",
          providerNameRequested: "Pristine",
          jobTitle: "Pristine",
          description: "Pristine",
          profilePic: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.firstName;
  var tmp;
  tmp = typeof match !== "object" ? validators.firstName.validate(input) : match._0;
  var match$1 = fieldsStatuses.lastName;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.lastName.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.dialCode
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.dialCodeCountry
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.phone
  };
  var match_0$3 = {
    TAG: "Ok",
    _0: input.providerNameRequested
  };
  var match_0$4 = {
    TAG: "Ok",
    _0: input.jobTitle
  };
  var match_0$5 = {
    TAG: "Ok",
    _0: input.description
  };
  var match_0$6 = {
    TAG: "Ok",
    _0: input.profilePic
  };
  var firstNameResult = tmp;
  var firstNameResult$1;
  if (firstNameResult.TAG === "Ok") {
    var lastNameResult = tmp$1;
    if (lastNameResult.TAG === "Ok") {
      var dialCodeResult = match_0;
      if (dialCodeResult.TAG === "Ok") {
        var dialCodeCountryResult = match_0$1;
        if (dialCodeCountryResult.TAG === "Ok") {
          var phoneResult = match_0$2;
          if (phoneResult.TAG === "Ok") {
            var providerNameRequestedResult = match_0$3;
            if (providerNameRequestedResult.TAG === "Ok") {
              var jobTitleResult = match_0$4;
              if (jobTitleResult.TAG === "Ok") {
                var descriptionResult = match_0$5;
                if (descriptionResult.TAG === "Ok") {
                  var profilePicResult = match_0$6;
                  if (profilePicResult.TAG === "Ok") {
                    return {
                            TAG: "Valid",
                            output: {
                              firstName: firstNameResult._0,
                              lastName: lastNameResult._0,
                              dialCode: dialCodeResult._0,
                              dialCodeCountry: dialCodeCountryResult._0,
                              phone: phoneResult._0,
                              providerNameRequested: providerNameRequestedResult._0,
                              jobTitle: jobTitleResult._0,
                              description: descriptionResult._0,
                              profilePic: profilePicResult._0
                            },
                            fieldsStatuses: {
                              firstName: {
                                TAG: "Dirty",
                                _0: firstNameResult,
                                _1: "Shown"
                              },
                              lastName: {
                                TAG: "Dirty",
                                _0: lastNameResult,
                                _1: "Shown"
                              },
                              dialCode: {
                                TAG: "Dirty",
                                _0: dialCodeResult,
                                _1: "Hidden"
                              },
                              dialCodeCountry: {
                                TAG: "Dirty",
                                _0: dialCodeCountryResult,
                                _1: "Hidden"
                              },
                              phone: {
                                TAG: "Dirty",
                                _0: phoneResult,
                                _1: "Hidden"
                              },
                              providerNameRequested: {
                                TAG: "Dirty",
                                _0: providerNameRequestedResult,
                                _1: "Hidden"
                              },
                              jobTitle: {
                                TAG: "Dirty",
                                _0: jobTitleResult,
                                _1: "Hidden"
                              },
                              description: {
                                TAG: "Dirty",
                                _0: descriptionResult,
                                _1: "Hidden"
                              },
                              profilePic: {
                                TAG: "Dirty",
                                _0: profilePicResult,
                                _1: "Hidden"
                              }
                            },
                            collectionsStatuses: undefined
                          };
                  }
                  firstNameResult$1 = firstNameResult;
                } else {
                  firstNameResult$1 = firstNameResult;
                }
              } else {
                firstNameResult$1 = firstNameResult;
              }
            } else {
              firstNameResult$1 = firstNameResult;
            }
          } else {
            firstNameResult$1 = firstNameResult;
          }
        } else {
          firstNameResult$1 = firstNameResult;
        }
      } else {
        firstNameResult$1 = firstNameResult;
      }
    } else {
      firstNameResult$1 = firstNameResult;
    }
  } else {
    firstNameResult$1 = firstNameResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            firstName: {
              TAG: "Dirty",
              _0: firstNameResult$1,
              _1: "Shown"
            },
            lastName: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            dialCode: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            dialCodeCountry: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            phone: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            providerNameRequested: {
              TAG: "Dirty",
              _0: match_0$3,
              _1: "Hidden"
            },
            jobTitle: {
              TAG: "Dirty",
              _0: match_0$4,
              _1: "Hidden"
            },
            description: {
              TAG: "Dirty",
              _0: match_0$5,
              _1: "Hidden"
            },
            profilePic: {
              TAG: "Dirty",
              _0: match_0$6,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurFirstNameField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.firstName, validators_firstName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: status,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLastNameField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.lastName, validators_lastName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: status,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDialCodeField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.dialCode, state.fieldsStatuses.dialCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: status,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDialCodeCountryField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.dialCodeCountry, state.fieldsStatuses.dialCodeCountry, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: status,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPhoneField" :
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.phone, state.fieldsStatuses.phone, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: status,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurProviderNameRequestedField" :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.providerNameRequested, state.fieldsStatuses.providerNameRequested, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: status,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurJobTitleField" :
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.jobTitle, state.fieldsStatuses.jobTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: status,
                                  description: init.description,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDescriptionField" :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.description, state.fieldsStatuses.description, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: status,
                                  profilePic: init.profilePic
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurProfilePicField" :
                  var result$8 = Formality.validateFieldOnBlurWithoutValidator(state.input.profilePic, state.fieldsStatuses.profilePic, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  dialCode: init.dialCode,
                                  dialCodeCountry: init.dialCodeCountry,
                                  phone: init.phone,
                                  providerNameRequested: init.providerNameRequested,
                                  jobTitle: init.jobTitle,
                                  description: init.description,
                                  profilePic: status
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateFirstNameField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.firstName, state.submissionStatus, validators_firstName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: status,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLastNameField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.lastName, state.submissionStatus, validators_lastName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: status,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDialCodeField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.dialCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: status,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDialCodeCountryField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.dialCodeCountry, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: status,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePhoneField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.phone, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: status,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateProviderNameRequestedField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.providerNameRequested, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: status,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateJobTitleField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.jobTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: status,
                                            description: init.description,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDescriptionField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.description, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: status,
                                            profilePic: init.profilePic
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateProfilePicField" :
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$8.profilePic, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            dialCode: init.dialCode,
                                            dialCodeCountry: init.dialCodeCountry,
                                            phone: init.phone,
                                            providerNameRequested: init.providerNameRequested,
                                            jobTitle: init.jobTitle,
                                            description: init.description,
                                            profilePic: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateFirstName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateFirstNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLastName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLastNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDialCode: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDialCodeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDialCodeCountry: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDialCodeCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePhone: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePhoneField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateProviderNameRequested: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProviderNameRequestedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateJobTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateJobTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateProfilePic: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProfilePicField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurFirstName: (function () {
              dispatch("BlurFirstNameField");
            }),
          blurLastName: (function () {
              dispatch("BlurLastNameField");
            }),
          blurDialCode: (function () {
              dispatch("BlurDialCodeField");
            }),
          blurDialCodeCountry: (function () {
              dispatch("BlurDialCodeCountryField");
            }),
          blurPhone: (function () {
              dispatch("BlurPhoneField");
            }),
          blurProviderNameRequested: (function () {
              dispatch("BlurProviderNameRequestedField");
            }),
          blurJobTitle: (function () {
              dispatch("BlurJobTitleField");
            }),
          blurDescription: (function () {
              dispatch("BlurDescriptionField");
            }),
          blurProfilePic: (function () {
              dispatch("BlurProfilePicField");
            }),
          firstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.firstName),
          lastNameResult: Formality.exposeFieldResult(state.fieldsStatuses.lastName),
          dialCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.dialCode),
          dialCodeCountryResult: Formality.exposeFieldResult(state.fieldsStatuses.dialCodeCountry),
          phoneResult: Formality.exposeFieldResult(state.fieldsStatuses.phone),
          providerNameRequestedResult: Formality.exposeFieldResult(state.fieldsStatuses.providerNameRequested),
          jobTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.jobTitle),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          profilePicResult: Formality.exposeFieldResult(state.fieldsStatuses.profilePic),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.firstName;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.lastName;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.dialCode;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.dialCodeCountry;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.phone;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.providerNameRequested;
              if (typeof tmp$5 === "object") {
                return true;
              }
              var tmp$6 = match.jobTitle;
              if (typeof tmp$6 === "object") {
                return true;
              }
              var tmp$7 = match.description;
              if (typeof tmp$7 === "object") {
                return true;
              }
              var tmp$8 = match.profilePic;
              if (typeof tmp$8 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var UserDashboardEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function UserDashboardEditForm$1(props) {
  var user = props.user;
  var initialInput = React.useMemo((function () {
          return {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  dialCode: user.dialCode,
                  dialCodeCountry: user.dialCodeCountry,
                  phone: user.phone,
                  providerNameRequested: user.providerNameRequested,
                  jobTitle: user.jobTitle,
                  description: user.description,
                  profilePic: user.profilePic
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          console.log(output);
          $$Promise.wait(Api_User.updateDashboard(user.id, {
                    firstName: output.firstName,
                    lastName: output.lastName,
                    dialCode: output.dialCode,
                    dialCodeCountry: output.dialCodeCountry,
                    phone: output.phone,
                    providerNameRequested: output.providerNameRequested,
                    jobTitle: output.jobTitle,
                    description: output.description,
                    profilePic: output.profilePic
                  }), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_User.Dashboard.edit(user.id));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "UserDashboardEditForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "UserDashboardEditForm.make"
                              }, "UpdateUser::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var profileImage = UserEditProfileData.Input.getProfileImage(form.input.profilePic, Globals.CloudinaryCloudName);
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match = form.input.profilePic;
  var tmp = match === "" ? JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(IconUserAvatar.make, {
                        size: "XXXXXL",
                        className: css.defaultProfilePic
                      }),
                  className: css.uploadedImage
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(Button.make, {
                        size: "SM",
                        color: "Teal",
                        buttonClassName: css.uploadDocumentButton,
                        children: [
                          JsxRuntime.jsx(IconAdditionalUpload.make, {
                                size: "MD",
                                color: "White"
                              }),
                          "Upload Image"
                        ]
                      }),
                  className: css.uploadLogo
                })
          ],
          className: css.profilePicContainer
        }) : JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx("img", {
                        alt: "Profile Image",
                        src: profileImage
                      }),
                  className: css.uploadedImage
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(Button.make, {
                        size: "SM",
                        color: "Teal",
                        buttonClassName: css.uploadDocumentButton,
                        children: [
                          JsxRuntime.jsx(IconAdditionalUpload.make, {
                                size: "MD",
                                color: "White"
                              }),
                          "Upload New Image"
                        ]
                      }),
                  className: css.uploadLogo
                })
          ],
          className: css.profilePicContainer
        });
  var match$1 = form.status;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" || match$1.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(ErrorMessage.make, {
                className: css.error,
                children: "Something went wrong."
              }),
          className: css.formRow
        });
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Uploader.make, {
                            allowed: [
                              "Jpg",
                              "Png"
                            ],
                            multiple: false,
                            className: css.uploader,
                            upload: (function (files) {
                                Belt_Array.map(files, (function (file) {
                                        var match = Uploader.FileType.fromMime(file.type);
                                        switch (match) {
                                          case "Jpg" :
                                          case "Png" :
                                              break;
                                          default:
                                            return ;
                                        }
                                        $$Promise.wait(Api.uploadFilesToCloudinary({
                                                  NAME: "Image",
                                                  VAL: file
                                                }), (function (x) {
                                                if (x.TAG !== "Ok") {
                                                  return SentryLogger.error1({
                                                              rootModule: "UserDashboardEditForm",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "UserDashboardEditForm.make"
                                                            }, "UserEditProfileGeneralTab::UploadImageToCloudinary::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                                var res = UserEditProfileData.ImageResponse.fromJson(x._0);
                                                form.updateProfilePic((function (input, value) {
                                                        return {
                                                                firstName: input.firstName,
                                                                lastName: input.lastName,
                                                                dialCode: input.dialCode,
                                                                dialCodeCountry: input.dialCodeCountry,
                                                                phone: input.phone,
                                                                providerNameRequested: input.providerNameRequested,
                                                                jobTitle: input.jobTitle,
                                                                description: input.description,
                                                                profilePic: value
                                                              };
                                                      }), res.public_id);
                                              }));
                                      }));
                              }),
                            setUploadHandler: (function (prim) {
                                
                              }),
                            children: tmp
                          }),
                      className: css.formRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--first-name",
                                      children: "First Name"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "user-form--first-name",
                                      value: form.input.firstName,
                                      placeholder: "First Name",
                                      onChange: (function ($$event) {
                                          form.updateFirstName((function (input, value) {
                                                  return {
                                                          firstName: value,
                                                          lastName: input.lastName,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: input.phone,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), $$event.target.value);
                                        })
                                    }),
                                fieldError(form.firstNameResult)
                              ],
                              className: css.fieldContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--last-name",
                                      children: "Last Name"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "user-form--last-name",
                                      value: form.input.lastName,
                                      placeholder: "Last Name",
                                      onChange: (function ($$event) {
                                          form.updateLastName((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: value,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: input.phone,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), $$event.target.value);
                                        })
                                    }),
                                fieldError(form.lastNameResult)
                              ],
                              className: css.fieldContainer
                            })
                      ],
                      className: Cx.cx([
                            css.formRow,
                            css.twoColumn
                          ])
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--phone",
                                      children: "Phone Number"
                                    }),
                                JsxRuntime.jsx(TelephoneField.make, {
                                      id: "user-form--phone",
                                      value: form.input.dialCode + form.input.phone,
                                      defaultCountry: "us",
                                      onChange: (function (phoneValue, selectedCountry) {
                                          var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                          form.updatePhone((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: input.lastName,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: value,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), Js_string.replace(country.dialCode, " ", phoneValue).trim());
                                          form.updateDialCode((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: input.lastName,
                                                          dialCode: value,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: input.phone,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), "+" + country.dialCode);
                                          form.updateDialCodeCountry((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: input.lastName,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: value,
                                                          phone: input.phone,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), country.iso2.toUpperCase());
                                        }),
                                      preferredCountries: ["us"]
                                    })
                              ],
                              className: css.fieldContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--email",
                                      children: "Email Address"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "user-form--email",
                                      value: user.email,
                                      placeholder: "example@email.com",
                                      disabled: true
                                    })
                              ],
                              className: css.fieldContainer
                            })
                      ],
                      className: Cx.cx([
                            css.formRow,
                            css.twoColumn
                          ])
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--company",
                                      children: "Company"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "user-form--company",
                                      value: form.input.providerNameRequested,
                                      placeholder: "Company",
                                      onChange: (function ($$event) {
                                          form.updateProviderNameRequested((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: input.lastName,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: input.phone,
                                                          providerNameRequested: value,
                                                          jobTitle: input.jobTitle,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), $$event.target.value);
                                        })
                                    }),
                                fieldError(form.providerNameRequestedResult)
                              ],
                              className: css.fieldContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "user-form--job-title",
                                      children: "Job Title"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "user-form--last-name",
                                      value: form.input.jobTitle,
                                      placeholder: "Job Title",
                                      onChange: (function ($$event) {
                                          form.updateJobTitle((function (input, value) {
                                                  return {
                                                          firstName: input.firstName,
                                                          lastName: input.lastName,
                                                          dialCode: input.dialCode,
                                                          dialCodeCountry: input.dialCodeCountry,
                                                          phone: input.phone,
                                                          providerNameRequested: input.providerNameRequested,
                                                          jobTitle: value,
                                                          description: input.description,
                                                          profilePic: input.profilePic
                                                        };
                                                }), $$event.target.value);
                                        })
                                    }),
                                fieldError(form.jobTitleResult)
                              ],
                              className: css.fieldContainer
                            })
                      ],
                      className: Cx.cx([
                            css.formRow,
                            css.twoColumn
                          ])
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Label.make, {
                                    forId: "user-form--description",
                                    children: "BIO"
                                  }),
                              JsxRuntime.jsx(Textarea.make, {
                                    id: "user-form--description",
                                    value: form.input.description,
                                    placeholder: "Brief professional description.",
                                    onChange: (function ($$event) {
                                        form.updateDescription((function (input, value) {
                                                return {
                                                        firstName: input.firstName,
                                                        lastName: input.lastName,
                                                        dialCode: input.dialCode,
                                                        dialCodeCountry: input.dialCodeCountry,
                                                        phone: input.phone,
                                                        providerNameRequested: input.providerNameRequested,
                                                        jobTitle: input.jobTitle,
                                                        description: value,
                                                        profilePic: input.profilePic
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.descriptionResult)
                            ],
                            className: css.fieldContainer
                          }),
                      className: css.formRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "LG",
                              color: "White",
                              expanded: true,
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  Url.visit(Routes_User.Dashboard.index);
                                }),
                              children: "Cancel"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "LG",
                              color: "Primary",
                              expanded: true,
                              disabled: form.submitting,
                              visuallyDisabled: false,
                              busy: form.submitting,
                              submit: true,
                              children: "Save User"
                            })
                      ],
                      className: Cx.cx([
                            css.formRow,
                            css.twoColumn
                          ])
                    }),
                tmp$1
              ],
              className: css.userForm,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var ReactTelInputData;

var make = UserDashboardEditForm$1;

export {
  css ,
  ReactTelInputData ,
  UserDashboardEditForm ,
  make ,
}
/* css Not a pure module */
